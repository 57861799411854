import { Link } from "gatsby"
import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const MainMenuItem = ({ item, mobileClose }) => {
  return (
    <>
      {!item.children ? (
        <>
          <div className="relative z-10 ">
            <div className=" flex ">
              <Link
                to={"/" + item.url}
                partiallyActive={item.url === "" ? false : true}
                className="text-gray-500 group w-full  rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none   px-3 py-3  hover:bg-gray-100"
                onClick={mobileClose}
              >
                <span>{item.menuName}</span>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <Popover className="z-50 relative">
          {({ open, close }) => (
            <>
              <div className="relative z-10 ">
                <div className=" flex  ">
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group  rounded-md flex justify-between w-full lg:w-auto lg:inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none  px-3 py-3  hover:bg-gray-100"
                    )}
                  >
                    <span>{item.menuName}</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-1 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1"
              >
                <Popover.Panel
                  static
                  className="lg:absolute z-10 inset-x-0 transition transform lg:shadow-lg  w-full lg:w-80"
                >
                  <div className="relative max-w-7xl mx-auto grid grid-cols-1 ">
                    <nav className="grid  bg-white border-blue-100 border pb-10 rounded-md lg:px-3">
                      <h2 id="solutionsHeading" className="sr-only">
                        {item.menuName}
                      </h2>
                      <div>
                        <ul className="my-3 space-y-6">
                          <li className="flow-root ">
                            <Link
                              to={"/" + item.url}
                              partiallyActive={item.url === "" ? false : true}
                              className="-mt-3 lg:-m-3 p-3 bg-blue-600 font-bold flex items-center rounded-t-md text-base tracking-wide uppercase text-blue-100 hover:bg-blue-700 transition ease-in-out duration-150"
                              activeClassName="  "
                              onClick={() => {
                                close()
                                mobileClose()
                              }}
                            >
                              {/* <page.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" /> */}
                              <span className="ml-4">{item.menuName}</span>
                            </Link>
                          </li>
                          {item.children.map((page, i) => (
                            <li key={i} className="flow-root">
                              <Link
                                to={"/" + page.url}
                                partiallyActive={page.url === "" ? false : true}
                                className="-m-3 p-3 flex items-center rounded-md  text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                activeClassName=" text-blue-500 hover:text-blue-600 border-blue-600 font-bold "
                                onClick={() => {
                                  close()
                                  mobileClose()
                                }}
                              >
                                {/* <page.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" /> */}
                                <span className="ml-4">{page.menuName}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </nav>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  )
}

export default MainMenuItem
