import axios from "axios"

const sign = require("jwt-encode")
const secret =
  "hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y"
const data = {
  name: "Malgen",
}
const jwt = sign(data, secret)

let URL
let API_ROUTE
const STAGE = process.env.NODE_ENV

if (STAGE === "development") {
  URL = "http://localhost/emocio/admin/"
  // URL = 'http://localhost/plysovnik/';
  //API_ROUTE = "https://localhost:8001/"
  API_ROUTE = "http://localhost:8000/"
  //API_ROUTE = "https://api.malgen.cz/"
  // URL = 'https://admin.plysovnik.cz/public/';
} else {
  URL = "https://admin.emocio.cz/"
  API_ROUTE = "https://api.emocio.cz/"
}

const axiosInstance = axios.create({
  // withCredentials: true,
  baseURL: URL + "public/",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ` + jwt,
  },
})

export const CONFIG_APP_API_ROUTE = API_ROUTE
export default axiosInstance
