exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-formular-odeslan-js": () => import("./../../../src/pages/formular-odeslan.js" /* webpackChunkName: "component---src-pages-formular-odeslan-js" */),
  "component---src-pages-kosik-js": () => import("./../../../src/pages/kosik.js" /* webpackChunkName: "component---src-pages-kosik-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-vyhledavani-js": () => import("./../../../src/templates/vyhledavani.js" /* webpackChunkName: "component---src-templates-vyhledavani-js" */)
}

